// npm
import React, { memo } from 'react'
import { Stack } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

// components
import CmsLinkButton from '@atoms/buttons/CmsLinkButton'
import TextContent, { TextContentProps } from '@molecules/text/TextContent'

export interface SectionTextContentGridLayoutProps extends TextContentProps {
  button?: Queries.DatoCmsButton
}

const SectionTextContentGridLayout = ({
  text,
  smallHeading,
  mainHeading,
  button,
}: SectionTextContentGridLayoutProps) => {
  return (
    <Grid
      container
      spacing={0}
      mb={!text ? { xs: 6, md: 8 } : { xs: 4, md: 6 }}
    >
      <Grid xs={12} md={8} lg={6}>
        <TextContent
          smallHeading={smallHeading}
          mainHeading={mainHeading}
          text={text}
        />
      </Grid>
      {button && (
        <Grid xs={12} md={4} lg={6} display="flex" alignItems="stretch">
          <Stack
            direction="row"
            alignItems="flex-end"
            justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
            width="100%"
            height="100%"
            pt={{ xs: 3, md: 0 }}
          >
            <CmsLinkButton
              button={button}
              internalButtonLinkProps={{ buttonVariant: 'text' }}
            />
          </Stack>
        </Grid>
      )}
    </Grid>
  )
}

export default memo(SectionTextContentGridLayout)

// npm
import React from 'react'
import { graphql, PageProps } from 'gatsby'

// root
import { pageContextProps } from '../../gatsby-node'

// components
import Sections from '@molecules/sections/Sections'
import Layout from '@organisms/global/Layout'
import SectionProjectSliderTemplate from '@molecules/sections/sectionComponents/templates/SectionProjectSliderTemplate'
// import SectionQuotes from '@molecules/sections/sectionComponents/SectionQuotes'

interface Props {
  data: Queries.ServiceDetailQuery
  pageContext: PageProps & pageContextProps
}

const ServiceDetail = ({ data, pageContext }: Props) => {
  const d = data.datoCmsService
  const global = data.datoCmsGlobal

  return (
    <Layout
      activeNavSlug={pageContext.activeNavSlug}
      metadata={{
        seoTags: d.seoMetaTags,
      }}
    >
      <Sections sections={d.sections} />
      <SectionProjectSliderTemplate
        smallHeading={global.relatedProjectsSection[0].smallHeading}
        mainHeading={`Recent ${d.name} ${global.relatedProjectsSection[0].mainHeading}`}
        text={global.relatedProjectsSection[0].text}
        bgColor={global.relatedProjectsSection[0].backgroundColor.name}
        button={global.relatedProjectsSection[0].viewAllButton[0]}
        projects={data.allDatoCmsProject.edges}
      />
      {/* <SectionQuotes projects={data.allDatoCmsProject.edges} /> */}
    </Layout>
  )
}

export const query = graphql`
  query ServiceDetail($originalId: String!) {
    datoCmsService(originalId: { eq: $originalId }) {
      name
      sections {
        ... on DatoCmsSectionContentImage {
          ...sectionContentImage
        }
        ... on DatoCmsSectionBulletColumn {
          ...sectionBulletColumns
        }
        ... on DatoCmsSectionIconBulletCell {
          ...sectionIconBulletCells
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      seoMetadata {
        description
      }
    }
    datoCmsGlobal {
      relatedProjectsSection {
        ...sectionProjectSlider
      }
    }
    allDatoCmsProject(
      filter: { services: { elemMatch: { originalId: { eq: $originalId } } } }
    ) {
      edges {
        node {
          ...projectCard
          ...quoteBgImages
          summaryImageQuoteSectionOpacity
          quote {
            model {
              apiKey
            }
            quote {
              ...quote
            }
          }
        }
      }
    }
    otherServices: allDatoCmsService(
      filter: { originalId: { ne: $originalId } }
    ) {
      edges {
        node {
          ...serviceCard
        }
      }
    }
  }
`

export default ServiceDetail

// npm
import React, { memo } from 'react'
import loadable from '@loadable/component'

// components
const SectionContentVideo = loadable(
  () => import('@molecules/sections/sectionComponents/SectionContentVideo')
)
const SectionContentImage = loadable(
  () => import('@molecules/sections/sectionComponents/SectionContentImage')
)
const SectionText = loadable(
  () => import('@molecules/sections/sectionComponents/SectionText')
)
const SectionClientLogoSlider = loadable(
  () => import('@molecules/sections/sectionComponents/SectionClientLogoSlider')
)
const SectionServiceSlider = loadable(
  () => import('@molecules/sections/sectionComponents/SectionServiceSlider')
)
const SectionProjectSlider = loadable(
  () => import('@molecules/sections/sectionComponents/SectionProjectSlider')
)
const SectionBulletColumns = loadable(
  () => import('@molecules/sections/sectionComponents/SectionBulletColumns')
)

type Section =
  | Queries.DatoCmsSectionContentVideo
  | Queries.DatoCmsSectionContentImage
  | Queries.DatoCmsSectionClientLogoSlider
  | Queries.DatoCmsSectionServiceSlider
  | Queries.DatoCmsSectionProjectSlider
  | Queries.DatoCmsSectionBulletColumn
  | Queries.DatoCmsSectionIconBulletCell

export interface SectionsProps {
  sections: Section[]
}

const Sections = ({ sections }: SectionsProps) => {
  return (
    <>
      {sections.map((item: any, index: number) => {
        if (item && item.model && item.model.apiKey) {
          switch (item.model.apiKey) {
            case 'section_content_video': {
              const section: Queries.DatoCmsSectionContentVideo = item
              return (
                <SectionContentVideo
                  key={section.originalId}
                  bgColor={section.backgroundColor.name}
                  text={section.textNode.childMarkdownRemark.html}
                  mainHeading={section.mainHeading}
                  smallHeading={section.smallHeading}
                  image={section.image.gatsbyImageData}
                  button={section.button[0]}
                  isFirstSection={index === 0 ? true : false}
                />
              )
            }
            case 'section_content_image': {
              const section: Queries.DatoCmsSectionContentImage = item
              if (section.content[0]) {
                return (
                  <SectionContentImage
                    key={section.originalId}
                    bgColor={section.backgroundColor.name}
                    alternateLayout={section.alternateLayout}
                    imageOpacity={section.imageOpacity}
                    xsImage={section.xsImage.gatsbyImageData}
                    mdImage={section.mdImage.gatsbyImageData}
                    lgImage={section.lgImage.gatsbyImageData}
                    mobileImageVariant={section.mobileImageVariant}
                    sectionContent={section.content[0]}
                    isFirstSection={index === 0}
                  />
                )
              }
              break
            }
            case 'section_text': {
              const section: Queries.DatoCmsSectionText = item
              return (
                <SectionText
                  key={section.originalId}
                  bgColor={section.backgroundColor.name}
                  sectionContent={section.content[0]}
                  isFirstSection={index === 0}
                />
              )
            }
            case 'section_client_logo_slider': {
              const section: Queries.DatoCmsSectionClientLogoSlider = item
              return (
                <SectionClientLogoSlider
                  key={section.originalId}
                  bgColor={section.backgroundColor.name}
                />
              )
            }
            case 'section_service_slider': {
              const section: Queries.DatoCmsSectionServiceSlider = item
              return (
                <SectionServiceSlider
                  key={section.originalId}
                  bgColor={section.backgroundColor.name}
                  text={section.text}
                  mainHeading={section.mainHeading}
                  smallHeading={section.smallHeading}
                  isFirstSection={index === 0}
                />
              )
            }
            case 'section_project_slider': {
              const section: Queries.DatoCmsSectionProjectSlider = item
              return (
                <SectionProjectSlider
                  key={section.originalId}
                  bgColor={section.backgroundColor.name}
                  text={section.text}
                  mainHeading={section.mainHeading}
                  smallHeading={section.smallHeading}
                  button={section.viewAllButton[0]}
                  excludeTags={section.excludeTags}
                />
              )
            }
            case 'section_bullet_column': {
              const section: Queries.DatoCmsSectionBulletColumn = item
              return (
                <SectionBulletColumns
                  key={section.originalId}
                  text={section.text[0].text}
                  mainHeading={section.text[0].mainHeading}
                  smallHeading={section.text[0].smallHeading}
                  items={section.columns}
                  cardType="column"
                />
              )
            }
            case 'section_icon_bullet_cell': {
              const section: Queries.DatoCmsSectionIconBulletCell = item
              return (
                <SectionBulletColumns
                  key={section.originalId}
                  text={section.text[0].text}
                  mainHeading={section.text[0].mainHeading}
                  smallHeading={section.text[0].smallHeading}
                  items={section.cells}
                  cardType="icon-bullet-cell"
                />
              )
            }
          }
        }
      })}
    </>
  )
}

export default memo(Sections)

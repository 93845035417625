import React, { memo } from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import {
  Box,
  BoxProps,
  Palette,
  lighten,
  styled,
  useTheme,
} from '@mui/material'

// helpers
import getThemeColorValue from '@helpers/get-theme-color-value'

interface HoverProps {
  forceHover?: boolean
  bgColor: string
  flipHover?: boolean
}

interface Props extends BoxProps, HoverProps {
  tintImage: IGatsbyImageData
  colorImage: IGatsbyImageData
}

const backgroundImageStyles: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  cursor: 'pointer',
}
const foregroundImageStyles = ({ bgColor, forceHover }: HoverProps) => {
  const theme = useTheme()
  const styles: React.CSSProperties = {
    position: 'relative',
    display: 'block',
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    backgroundColor: lighten(getThemeColorValue(bgColor as keyof Palette), 0.1),
    transition: theme.transitions.create(['opacity'], {
      duration: 500,
    }),
    ['&:hover']: {
      opacity: 0,
    },
    ...(forceHover && {
      opacity: 0,
    }),
  }
  return styles
}

const Hover = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'forceHover' && prop !== 'bgColor',
})<HoverProps>(({ bgColor, theme }) => ({
  position: 'relative',
  display: 'block',
  backgroundColor: getThemeColorValue(bgColor as keyof Palette),
  overflow: 'hidden',
  width: '100%',
  height: '100%',
}))

const ColorImage = styled(GatsbyImage, {
  shouldForwardProp: (prop) =>
    prop !== 'forceHover' && prop !== 'bgColor' && prop !== 'flipHover',
})<HoverProps>(({ forceHover, flipHover, bgColor, theme }) => ({
  ...(flipHover && {
    ...foregroundImageStyles({ bgColor: bgColor, forceHover: forceHover }),
  }),
  ...(!flipHover && {
    ...backgroundImageStyles,
  }),
}))

const TintImageWrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'forceHover' && prop !== 'bgColor' && prop !== 'flipHover',
})<HoverProps>(({ forceHover, flipHover, bgColor, theme }) => ({
  ...(flipHover && {
    ...backgroundImageStyles,
  }),
  ...(!flipHover && {
    ...foregroundImageStyles({ bgColor: bgColor, forceHover: forceHover }),
  }),
}))

const TintImage = styled(GatsbyImage)(({ theme }) => ({
  cursor: 'pointer',
  opacity: 1,
  mixBlendMode: 'overlay',
  position: 'relative',
  display: 'block',
  width: '100%',
  height: '100%',
}))

const ImageHover = ({
  tintImage,
  colorImage,
  forceHover,
  flipHover,
  bgColor,
  ...props
}: Props) => {
  return (
    <Hover bgColor={bgColor} {...props}>
      {flipHover ? (
        <>
          <TintImageWrapper
            forceHover={forceHover}
            bgColor={bgColor}
            flipHover={flipHover}
          >
            <TintImage image={tintImage} alt="" />
          </TintImageWrapper>
          <ColorImage
            image={colorImage}
            alt=""
            forceHover={forceHover}
            bgColor={bgColor}
            flipHover={flipHover}
          />
        </>
      ) : (
        <>
          <ColorImage
            image={colorImage}
            alt=""
            forceHover={forceHover}
            bgColor={bgColor}
            flipHover={flipHover}
          />
          <TintImageWrapper
            forceHover={forceHover}
            bgColor={bgColor}
            flipHover={flipHover}
          >
            <TintImage image={tintImage} alt="" />
          </TintImageWrapper>
        </>
      )}
    </Hover>
  )
}

export default memo(ImageHover)

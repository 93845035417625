// npm
import React, { memo } from 'react'
import { Box, BoxProps, Container, styled, useTheme } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, FreeMode } from 'swiper'
import 'swiper/css'

// components
import ProjectCard from '@molecules/cards/ProjectCard'
import TextContentGridLayout, {
  SectionTextContentGridLayoutProps,
} from '@molecules/text/TextContentGridLayout'

type projectNode = {
  node: Queries.DatoCmsProject
}

export interface SectionProjectSliderTemplateProps
  extends BoxProps,
    SectionTextContentGridLayoutProps {
  bgColor: string
  projects: projectNode[]
  excludeTags?: boolean
}

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
}))

const SwiperContainer = styled(Box)(({ theme }) => ({
  ['.swiper-cards']: {
    overflow: 'visible',
  },
}))

const SectionProjectSliderTemplate = ({
  text,
  smallHeading,
  mainHeading,
  bgColor,
  button,
  projects,
  excludeTags,
  ...props
}: SectionProjectSliderTemplateProps) => {
  const theme = useTheme()
  const [currentIndex, setCurrentIndex] = React.useState<number>(0)
  const swiperRef = React.useRef<SwiperCore>()
  SwiperCore.use([Autoplay, FreeMode])

  return (
    <Wrapper
      component="section"
      overflow="hidden"
      bgcolor={`${bgColor}`}
      py={{ xs: 6, md: 10 }}
      {...props}
    >
      <Container maxWidth="xl">
        <TextContentGridLayout
          smallHeading={smallHeading}
          mainHeading={mainHeading}
          button={button}
          text={text}
        />
        <SwiperContainer>
          <Swiper
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper
            }}
            onActiveIndexChange={() => {
              setCurrentIndex(swiperRef.current.realIndex)
            }}
            loop
            autoplay={{
              delay: 4000,
              disableOnInteraction: true,
            }}
            slidesPerView={1}
            spaceBetween={0}
            observer={true}
            observeParents={true}
            freeMode={{
              sticky: true,
            }}
            className="swiper-cards"
            breakpoints={{
              [theme.breakpoints.values.xs]: {
                slidesPerView: 1,
              },
              [theme.breakpoints.values.sm]: {
                slidesPerView: 2,
              },
              [theme.breakpoints.values.lg]: {
                slidesPerView: 3,
              },
            }}
          >
            <Box position="relative">
              {projects.map((item: any, index: number) => {
                const project: Queries.DatoCmsProject = item.node
                return (
                  <SwiperSlide key={project.originalId}>
                    <ProjectCard
                      active={currentIndex === index ? true : false}
                      title={project.title}
                      clientName={project.client.name}
                      image={project.image.gatsbyImageData}
                      tintImage={project.tintImage.gatsbyImageData}
                      services={project.services}
                      slug={project.slug}
                      apiKey={project.model.apiKey}
                      excludeTags={excludeTags}
                      bgColor={bgColor}
                    />
                  </SwiperSlide>
                )
              })}
            </Box>
          </Swiper>
        </SwiperContainer>
      </Container>
    </Wrapper>
  )
}

export default memo(SectionProjectSliderTemplate)

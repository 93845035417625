// npm
import React from 'react'
import { Link as GatsbyLink, navigate } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import { Box, Palette, Stack, StackProps, alpha, styled } from '@mui/material'
import { HiArrowRight } from 'react-icons/hi'

// components
import ImageHover from '@atoms/media/ImageHover'

// helpers
import slugPrefix from '@helpers/slugPrefix'
import getThemeColorValue from '@helpers/get-theme-color-value'

interface HoverProps {
  active?: boolean
  bgColor?: string
}

interface Props extends HoverProps, StackProps {
  image: IGatsbyImageData
  tintImage: IGatsbyImageData
  clientName: string
  services: Queries.DatoCmsService[]
  slug: string
  apiKey: string
  excludeTags?: boolean
}

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})<HoverProps>(({ active, theme }) => ({
  position: 'relative',
  textAlign: 'left',
  transition: theme.transitions.create(['margin-top']),
  ...(active && {
    marginTop: `-${theme.spacing(2)}`,
  }),
}))

const InactiveGradient = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'bgColor',
})<HoverProps>(({ active, bgColor, theme }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: '1px',
  left: 0,
  right: 0,
  background: `linear-gradient(180deg, ${alpha(
    getThemeColorValue(bgColor as keyof Palette),
    0
  )} 0, ${alpha(getThemeColorValue(bgColor as keyof Palette), 1)} 100%)`,
  opacity: 0.9,
  transition: theme.transitions.create(['opacity']),
  ...(active && {
    opacity: 0,
  }),
}))

const Content = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'bgColor',
})<HoverProps>(({ active, bgColor, theme }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  overflow: 'hidden',
  top: 0,
  left: 0,
  right: 0,
  cursor: 'pointer',
  boxSizing: 'border-box',
  background: `linear-gradient(180deg, ${alpha(
    getThemeColorValue(bgColor as keyof Palette),
    0
  )}, ${alpha(theme.palette.common.black, 0.58)} 50%, 
    ${alpha(theme.palette.common.black, 0.75)} 100%)`,
  ['&:after']: {
    content: '""',
    display: 'block',
    width: '100%',
    height: '10px',
    position: 'absolute',
    left: 0,
    bottom: '-10px',
    backgroundColor: alpha(theme.palette.common.black, 0.75),
  },
  opacity: 0,
  transition: theme.transitions.create(['opacity'], { duration: 500 }),
  ...(active && {
    opacity: 1,
  }),
}))

const WrapperGatsbyLink = styled(GatsbyLink)(({ theme }) => ({
  ['&:hover']: {
    color: alpha(theme.palette.text.primary, 0.8),
  },
}))

const ServiceName = styled(GatsbyLink)(({ theme }) => ({
  fontSize: '1.6rem',
  color: alpha(theme.palette.text.primary, 0.8),
}))
const HeadingArrow = styled(HiArrowRight)(({ theme }) => ({
  display: 'inline-block',
  fontSize: '1.6rem',
  marginLeft: '0.2rem',
  color: theme.palette.primary.main,
}))

const ProjectCard = ({
  active,
  image,
  tintImage,
  clientName,
  services,
  slug,
  apiKey,
  excludeTags,
  bgColor,
}: Props) => {
  const [isHover, setIsHover] = React.useState(false)

  const url = slugPrefix({
    slug: slug,
    apiKey: apiKey,
  })
  return (
    <Wrapper
      active={active}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <ImageHover
        tintImage={tintImage}
        colorImage={image}
        forceHover={active ? active : isHover}
        bgColor={bgColor}
      />
      <InactiveGradient active={active ? active : isHover} bgColor={bgColor} />
      <WrapperGatsbyLink to={url} aria-label={`View ${clientName} project`} />

      <Content
        alignItems="flex-start"
        justifyContent="flex-end"
        p={{ xs: 2, md: 3, lg: 4 }}
        active={active ? active : isHover}
        bgColor={bgColor}
        onClick={() => navigate(url)}
      >
        <Box
          component="h3"
          typography="lgBody"
          fontWeight="fontWeightMedium"
          color="white"
          mb={0}
        >
          <GatsbyLink to={url}>
            {clientName} <HeadingArrow />
          </GatsbyLink>
        </Box>
        {!excludeTags && (
          <div>
            {services.map((service: Queries.DatoCmsService, index: number) => {
              return (
                <Box
                  component="span"
                  display="inline-block"
                  key={service.originalId}
                >
                  <ServiceName
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                    to={slugPrefix({
                      slug: service.slug,
                      apiKey: service.model.apiKey,
                    })}
                  >
                    {service.name}
                  </ServiceName>
                  {index + 1 < services.length && <>,&nbsp;</>}
                </Box>
              )
            })}
          </div>
        )}
      </Content>
    </Wrapper>
  )
}

export default React.memo(ProjectCard)
